<template>
  <div>
  <v-dialog
      v-model="activo"
      width="850"
      :persistent="activo"
      scrollable
    >
      <v-card>
        <v-card-title class="">
          <div style="font-size: 20px">Detalles de Aceptación</div>
          <v-spacer></v-spacer>
          <v-btn right icon @click="activo = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-row class="pt-2">
            <!-- Articulos para ser aceptados -->
            <v-col cols="12" class="py-1">
              Artículos a aceptar
              <v-data-table
                class="cebra elevation-2 mt-2"
                :headers="header"
                :items="det_aceptacion"
                :loading="load"
                dense
              >
                <!-- Articulo -->
                 <template v-slot:[`item.articulo`]="{item}">
                    {{ item.articulo_codigo }} - {{ item.articulo_nombre }}
                 </template>
                <!-- No hay datos -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="400"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    No hay artículos para aceptar
                  </v-alert>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" class="py-1">
              Observación
              <v-textarea
                v-model="observacion"
                rows="3"
                hide-details
                auto-grow
                outlined
                dense
                clearable
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- BOTONES -->
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            class="mr-2"
            color="secondary"
            @click="activo = false"
          >cancelar</v-btn>
          <BtnConfirmar
            :texto="`¿Está seguro de aceptar el remito?`"
            :nombre="'GUARDAR CAMBIOS'"
            :loading="load"
            @action="guardarEmit()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  </template>
  
  <script>
  import moment from 'moment';
  import BtnConfirmar from '../../components/util/BtnConfirmar.vue';
  
  export default {
    name: 'ModalObsAceptRemito',
    props:{
      value: Boolean,
      det_aceptacion: Array, 
    },
    computed:{
      activo:{
        get(){
          return this.value;
        },
        set (value) {
          this.$emit('input', value)
        }
      },
    },
    data(){
      return{
        load: false,
        observacion: '',
        header: [
          { text: 'Nro. Remito', value: 'remito_numero', align: 'end' },
          { text: 'Artículo', value: 'articulo' },
          { text: 'Cantidad', value: 'cantidad', align: 'end' },
        ]
      }
    },
    created(){
    },
    methods:{
      async init(){
  
      },
      guardarEmit(){
        this.$emit('aceptar', this.observacion);
      },
    },
    components:{
      BtnConfirmar
    },
    watch:{
      activo: function(val){
        if(!val){
          this.observacion = '';
        }
      },
    }
  }
  </script>
  
  <style>
  
  </style>