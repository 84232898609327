<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <!-- Titulo -->
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <!-- Filtros -->
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="4" md="4" class="py-1">
                  Bodega Origen
                  <v-autocomplete
                    v-model="selectedBodOrigen"
                    item-text="bodega_nombre"
                    :items="bodegasDestino"
                    hide-details
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="py-1">
                  Bodega Destino
                  <v-autocomplete
                    v-model="selectedBodDestino"
                    item-text="bodega_nombre"
                    :items="bodegasOrigen"
                    hide-details
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="py-1">
                  Estados
                  <v-autocomplete
                    v-model="selectedEstado"
                    item-text="estado_nombre"
                    :items="estados"
                    hide-details
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="2" md="2" class="py-1">
                  Remito Número
                  <v-text-field
                    v-model="remitoNumero"
                    hide-details
                    outlined
                    dense
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" :sm="big ? 6 : 3" class="py-1">
                  Fecha
                  <FechaPickerRango
                    v-model="fechaRango"
                    :limpiar.sync="limpiarFecha"
                    @changeSize="changeSize"
                  />
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Listado de Remitos -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        :headers="headers"
        :items="remitos"
        :loading="load"
        :search="search"
        dense
      >
        <!-- Buscador en el datatable -->
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <!-- Acciones -->
        <template v-slot:[`item.acciones`]="{ item }">
          <v-btn
            small
            icon
            @click="aceptarRemito(item)"
          >
            <v-icon
              color="success"
              :title="item.estado_codigo == 1 ? 'Aceptar Remito' : 'Ver'"
              small
            >
              fas fa-{{ item.estado_codigo == 1 ? 'check' : 'eye' }}
            </v-icon>
          </v-btn>
          <v-btn
            small
            icon
            @click="generarPDF(item)"
          >
            <v-icon color="error" title="PDF" small>
              fas fa-file-pdf
            </v-icon>
          </v-btn>
          <v-tooltip bottom v-if="item.observacion">
            <template v-slot:activator="{ on }">
              <v-icon color="info" small v-on="on">
                fas fa-info-circle
              </v-icon>
            </template>
            <span>{{ item.observacion }}</span>
          </v-tooltip>
        </template>
        <!-- no data -->
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- Modal ver PDF -->
    <PDFViewer 
      v-model="dialogPdf"
      :pdf="pdf"
      :nombre="nombreVer"
      :titulo="nombreVer"
    />
  </v-row>
</template>

<script>
  import moment from 'moment'
  import FechaPickerRango from '../../components/util/FechaPickerRango.vue'
  import BtnFiltro from '../../components/util/BtnFiltro.vue'
  import SearchDataTable from '../../components/util/SearchDataTable.vue'
  import PDFViewer from '../../util/plantillas/PDFViewer.vue'
  import {reporteRemitoInterno} from '../../util/plantillas/pdfs'

export default {
  data() {
    return {
      panel: 0,
      selectedBodOrigen: null,
      bodegasOrigen: [],
      selectedBodDestino: null,
      bodegasDestino: [],
      selectedEstado: null,
      estados: [],
      remitoNumero: '',
      fechaRango: [ null, null ],
      limpiarFecha: false,
      big: false,
      load: false,
      headers: [
        { text: 'Número', align: 'center', value: 'remito_numero' },
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Bodega Origen', align: 'center', value: 'bodega_origen_nombre' },
        { text: 'Bodega Destino', align: 'center', value: 'bodega_destino_nombre' },
        { text: 'Estado', align: 'center', value: 'estado_nombre' },
        { text: 'Usuario', align: 'center', value: 'usuario_estado' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      remitos: [],
      search: '',
      dialogPdf: false,
      pdf: '',
      nombreVer: ''
    }
  },
  created () {
    this.initForm()
  },
  components: {
    FechaPickerRango,
    BtnFiltro,
    SearchDataTable,
    PDFViewer
  },
  methods: {
    async initForm () {
      this.$store.state.loading = true
      // ya tengo los datos en localStorage?
      if (localStorage.getItem('bodegasO') && JSON.parse(localStorage.getItem('bodegasO')).length > 0 &&
          localStorage.getItem('bodegasD') && JSON.parse(localStorage.getItem('bodegasD')).length > 0 &&
          localStorage.getItem('estadosRemitos') && JSON.parse(localStorage.getItem('estadosRemitos')).length > 0) {
        this.$store.state.loading = false
        this.bodegasOrigen = JSON.parse(localStorage.getItem('bodegasO'))
        this.bodegasDestino = JSON.parse(localStorage.getItem('bodegasD'))
        this.estados = JSON.parse(localStorage.getItem('estadosRemitos'))
      }
      else {
        let initPeticion = await this.$store.dispatch('remitos/initForm')
        this.$store.state.loading = false
        if (initPeticion.resultado == 1) {
          this.bodegasOrigen = initPeticion.bodegasO
          this.bodegasDestino = initPeticion.bodegasD
          this.estados = initPeticion.estados
        }
        else {
          this.bodegasOrigen = []
          this.bodegasDestino = []
          this.estados = []
          return this.$store.dispatch('show_snackbar', {
            text: initPeticion.msj,
            color: 'error'
          })
        }
      }
      const estado_pendiente = this.estados.find(estado => estado.estado_codigo == 1)
      if (estado_pendiente) this.selectedEstado = estado_pendiente
    },  
    filtrosVacios () {
      if (!this.selectedBodOrigen && !this.selectedBodDestino && !this.selectedEstado && this.remitoNumero.length === 0){
        return true
      }
      return false
    },
    async buscar () {
      if (this.filtrosVacios()) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar al menos un filtro para realizar la búsqueda.',
          color: 'info'
        })
      }
      this.remitos = []
      // consigo los datos que necesito para la consulta
      let bodegaOrigen = 0
      if (this.selectedBodOrigen) {
        bodegaOrigen = this.selectedBodOrigen.bodega_codigo
      }
      let bodegaDestino = 0
      if (this.selectedBodDestino) {
        bodegaDestino = this.selectedBodDestino.bodega_codigo
      }
      let estado = 0
      if (this.selectedEstado) {
        estado = this.selectedEstado.estado_codigo
      }
      let numeroRemito = 0
      if (this.remitoNumero.length > 0) {
        numeroRemito = this.remitoNumero
      }
      if(!this.fechaRango[0]){
        return this.$store.dispatch('show_snackbar', {
          text: "Se requiere ingresar una fecha inicial para el período.",
          color: 'info'
        })
      }
      let fechaHastaBus = ''
      if (this.fechaRango[1] && null || this.fechaRango[1] != undefined && this.fechaRango[1].length > 0) {
        fechaHastaBus = moment(this.fechaRango[1]).format('DD/MM/YYYY')
      }

      let datos = {
        bodega_origen: bodegaOrigen,
        bodega_destino: bodegaDestino,
        estado: estado,
        remito_numero: numeroRemito,
        fecha_desde: moment(this.fechaRango[0]).format('DD/MM/YYYY'),
        fecha_hasta: fechaHastaBus
      }
      this.load = true
      let remitosPeticion = await this.$store.dispatch('remitos/getRemitos', datos)
      this.load = false
      
      if (remitosPeticion.resultado != 1) {
        return this.$store.dispatch('show_snackbar', {
          text: remitosPeticion.msj,
          color: 'error'
        })
      }
      this.remitos = remitosPeticion.remitos
    },
    limpiar () {
      this.selectedBodDestino = null
      this.selectedBodOrigen  = null
      this.selectedEstado     = null
      this.remitoNumero = ''
      this.limpiarFecha = true
      const estado_pendiente = this.estados.find(estado => estado.estado_codigo == 1)
      if (estado_pendiente) this.selectedEstado = estado_pendiente
    },
    async generarPDF (item) {
      this.$store.state.loading = true
      let reportePeticion = await this.$store.dispatch('remitos/getRemitoPDF', { remito_numero: item.remito_numero })
      this.$store.state.loading = false

      if (reportePeticion.resultado == 1) {
        if (reportePeticion.enabezado.length > 0) {
          // formateo las fechas
          reportePeticion.enabezado[0].fecha = moment(reportePeticion.enabezado[0].fecha).format('DD/MM/YYYY')
          reportePeticion.enabezado[0].fecha_grabacion = moment(reportePeticion.enabezado[0].fecha_grabacion).format('DD/MM/YYYY')
          
          let pdfPeticion = await reporteRemitoInterno({
            enc: reportePeticion.enabezado[0],
            detalles: reportePeticion.detalles
          })

          if (pdfPeticion.resultado == 1) {
            this.nombreVer = 'Remito N°' + item.remito_numero
            this.pdf = pdfPeticion.pdf
            this.dialogPdf = true
          }
          else {
            this.$store.dispatch('show_snackbar', {
              text: pdfPeticion.msj,
              color: 'error'
            })
          }
        }
        else {
          this.$store.dispatch('show_snackbar', {
            text: 'El remito sobrepasa la antiguedad establecida.',
            color: 'info'
          })
        }
      }
      else {
        this.$store.dispatch('show_snackbar', {
          text: reportePeticion.msj,
          color: 'error'
        })
      }
    },
    aceptarRemito (item) {
      let path = '/aceptar-remito/' + item.remito_numero.toString()
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    changeSize (custom) {
      this.big = custom
    }
  }
}
</script>